import request from '@/utils/request'
// 社区活动
export function WeGetMySignupActivityPage(query) {
    return request({
        url: '/Activity/WeGetMySignupActivityPage',
        method: 'get',
        params: query
    })
}
export function WeGetMySignupActivityPages(query) {
    return request({
        url: '/PBActivity/WeGetMySignupActivityPage',
        method: 'get',
        params: query
    })
}